import { type UseMutateAsyncFunction, useMutation, useQuery } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { createAccount as createPsAccount } from '@/api/auth';
import { fetchApi, setAuthorizationHeader } from '@/api/client';
import { getUserNameFromToken, signIn as signInToCognito, signInWithAuthorizationCode } from '@/api/cognito';
import { type components } from '@/api/schemas';
import { Loading } from '@/components/Loading';

export type Scope = 'user' | 'initial';

type NullableGender = components['schemas']['NullableGender'];
type Gender = components['schemas']['Gender'];
type LegacySessionForPs = components['schemas']['LegacySessionForPs'];
type GetLegacySessionRequestForPs = components['schemas']['GetLegacySessionRequestForPs'];

type PatientInfo = {
  name: string;
  kana: string;
  gender: NullableGender;
  birthDate: string;
};

export interface CognitoIdentity {
  userId: string;
  providerName: string;
  providerType: string;
  issuer: string | null;
  primary: boolean;
  dateCreated: string;
}

export interface CognitoTokenData extends JwtPayload {
  at_hash: string;
  'cognito:groups': string[];
  email_verified: boolean;
  'cognito:username': string;
  origin_jti: string;
  identities: CognitoIdentity[];
  token_use: string;
  auth_time: number;
  email: string;
}

type ContextValue = {
  user?: CognitoTokenData;
  patients: Record<string, PatientInfo>;
  mainPatientId: string;
  currentPatientId: string;
  setCurrentPatientId: (patient: string) => void;
  isLoading: boolean;
  scopes: Scope[];
  signIn: (username?: string, password?: string) => Promise<boolean>;
  signInWithCode: (code: string) => Promise<
    | boolean
    | {
        accessToken: string;
        expiresIn: number;
        idToken: string;
        refreshToken: string;
        tokenType: string;
      }
  >;
  signInToCenterServer: UseMutateAsyncFunction<
    AxiosResponse<LegacySessionForPs, unknown>,
    Error,
    GetLegacySessionRequestForPs,
    unknown
  >;
  signOut: () => Promise<boolean>;
  createAccount: (params: { name: string; kana: string; birthDate: string; gender: Gender }) => Promise<string>;
  isSnsNeedRedirect: boolean;
  setIsSnsNeedRedirect: (enable: boolean) => void;
};

const DEFAULT_CONTEXT_VALUE: ContextValue = {
  user: undefined,
  patients: {},
  mainPatientId: '',
  currentPatientId: '',
  setCurrentPatientId: () => undefined,
  isLoading: true,
  scopes: [],
  signIn: async () => false,
  signInWithCode: async () => false,
  signInToCenterServer: async () => {
    throw new Error('Not implemented');
  },
  signOut: async () => false,
  createAccount: async () => '',
  isSnsNeedRedirect: false,
  setIsSnsNeedRedirect: () => undefined,
};

const context = createContext<ContextValue | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export function AuthProvider(props: Props) {
  const { children } = props;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const urlParams = {
    cognitoUserName: searchParams.get('cognitoUserName') || '',
    refreshToken: searchParams.get('refreshToken') || '',
    currentAccount: searchParams.get('currentAccount') || '',
  };

  const [user, setUser] = useState<CognitoTokenData | undefined>(undefined);

  const { mutateAsync: signInToCenterServer } = useMutation({
    mutationFn: (params: GetLegacySessionRequestForPs) =>
      fetchApi({
        url: '/ps/v1/legacy/session',
        method: 'post',
        data: {
          ...params,
          targetAccountId: params?.targetAccountId || currentPatientId,
          deviceCategory: '20',
        },
      }),
  });

  // Token save in memory
  const [tokenData, setTokenData] = useState<{
    idToken: string;
    accessToken: string;
  }>({
    idToken: '',
    accessToken: '',
  });
  const { idToken } = tokenData;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [refreshToken, setRefreshToken] = useLocalStorage('harmo-web-auth-refresh-token', urlParams.refreshToken);
  const [cognitoUserName, setCognitoUserName] = useLocalStorage(
    'harmo-web-auth-cognito-username',
    urlParams.cognitoUserName,
  );
  const [isSnsNeedRedirect, setIsSnsNeedRedirect] = useLocalStorage('harmo-web-need-redirect', false);

  const { data: userInfo } = useQuery({
    queryKey: ['userInfo', urlParams.refreshToken || refreshToken, urlParams.cognitoUserName || cognitoUserName],
    queryFn: () => fetchApi({ url: '/ps/v1/accounts/me', method: 'get' }).then((res) => res.data),
    enabled: !!user,
    staleTime: Infinity,
  });

  const { data: familyMembers } = useQuery({
    queryKey: ['fetchFamilyMembers', userInfo?.accountId],
    queryFn: () => fetchApi({ url: '/ps/v1/accounts/family', method: 'get' }).then((res) => res.data),
    enabled: !!userInfo?.accountId,
    staleTime: Infinity,
  });

  const patients: Record<string, PatientInfo> = useMemo(
    () =>
      userInfo
        ? {
            [userInfo.accountId]: {
              name: userInfo.name,
              kana: userInfo.kana,
              gender: userInfo.gender,
              birthDate: userInfo.birthDate || '',
            },
            ...Object.fromEntries(
              familyMembers?.family.map((member) => [
                member.accountId,
                {
                  name: member.name,
                  kana: member.kana,
                  gender: member.gender,
                  birthDate: member.birthDate || '',
                },
              ]) || [],
            ),
          }
        : {},
    [familyMembers?.family, userInfo],
  );

  const mainPatientId = userInfo?.accountId || '';

  const getDefaultCurrentAccount = useCallback(() => {
    const patientIds = Object.keys(patients);
    if (patientIds.length === 0) {
      return '';
    }
    if (
      urlParams.currentAccount &&
      patientIds.findIndex((patientId) => patientId === urlParams.currentAccount) !== -1
    ) {
      return urlParams.currentAccount;
    }
    return patientIds[0];
  }, [patients, urlParams.currentAccount]);

  const [currentPatientId, setCurrentPatientId] = useState(getDefaultCurrentAccount());

  useEffect(() => {
    setCurrentPatientId(getDefaultCurrentAccount());
  }, [getDefaultCurrentAccount]);

  useEffect(() => {
    if (urlParams.cognitoUserName && urlParams.refreshToken) {
      setCognitoUserName(urlParams.cognitoUserName);
      setRefreshToken(urlParams.refreshToken);
    }
  }, [
    cognitoUserName,
    navigate,
    refreshToken,
    setCognitoUserName,
    setRefreshToken,
    urlParams.cognitoUserName,
    urlParams.refreshToken,
  ]);

  const updateCurrentAuth = useCallback((idToken: string) => {
    if (!idToken) {
      return;
    }
    setAuthorizationHeader(idToken);
    const currentUser = jwtDecode<CognitoTokenData>(idToken);
    setUser(currentUser);
  }, []);

  const isLoading = !!idToken || !!refreshToken;

  const scopes = useMemo(() => {
    const newScopes: Scope[] = [];
    if (isLoading) {
      return newScopes;
    }

    newScopes.push('user');
    return newScopes;
  }, [isLoading]);

  // Actions for Auth below
  const signIn: ContextValue['signIn'] = useCallback(
    async (username?: string, password?: string) => {
      if (username && password) {
        // NOTE: ログイン画面からのログインの場合
        const result = await signInToCognito(username, password).catch(() => undefined);
        if (!result?.accessToken || !result?.idToken || !result?.refreshToken) {
          return false;
        }
        setCognitoUserName(result.user?.getUsername() || '');
        setRefreshToken(result.refreshToken);
        updateCurrentAuth(result.idToken);
        setTokenData((prev) => ({
          ...prev,
          accessToken: result.accessToken,
          idToken: result.idToken,
        }));
        return true;
      }

      console.log('signIn with refresh token', urlParams.refreshToken, urlParams.cognitoUserName);
      const result = await fetchApi({
        url: '/ps/v1/token',
        method: 'put',
        data: {
          cognitoUserName: urlParams.cognitoUserName || cognitoUserName,
          refreshToken: urlParams.refreshToken || refreshToken,
        },
      })
        .then((res) => res.data)
        .catch(() => undefined);
      if (!result) {
        navigate('/auth/sign-in');
        return false;
      }
      if (result.accessToken && result.idToken) {
        updateCurrentAuth(result.idToken);
        setTokenData((prev) => ({
          ...prev,
          accessToken: result.accessToken,
          idToken: result.idToken,
        }));
      }
      return true;
    },
    [
      cognitoUserName,
      navigate,
      refreshToken,
      setCognitoUserName,
      setRefreshToken,
      updateCurrentAuth,
      urlParams.cognitoUserName,
      urlParams.refreshToken,
    ],
  );

  const signInWithCode = useCallback(
    async (code: string) => {
      const result = await signInWithAuthorizationCode(code).catch(() => undefined);
      if (!result) {
        return false;
      }

      if (result.accessToken && result.idToken) {
        const isSignedUp = await fetchApi({
          url: '/ps/v1/accounts/me',
          method: 'get',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${result.idToken}` },
        })
          .then(() => true)
          .catch(() => false);

        if (!isSignedUp) {
          return result;
        }

        const userName = getUserNameFromToken(result.idToken);
        setCognitoUserName(userName);
        setRefreshToken(result.refreshToken);
        updateCurrentAuth(result.idToken);
        setTokenData((prev) => ({
          ...prev,
          accessToken: result.accessToken,
          idToken: result.idToken,
        }));
      }
      return true;
    },
    [setCognitoUserName, setRefreshToken, updateCurrentAuth],
  );

  const signOut = useCallback(async () => {
    setUser(undefined);
    setCurrentPatientId('');
    setTokenData({
      accessToken: '',
      idToken: '',
    });
    setRefreshToken('');
    setCognitoUserName('');
    updateCurrentAuth('');
    return true;
  }, [setCognitoUserName, setRefreshToken, updateCurrentAuth]);

  const createAccount = useCallback(
    (params: { name: string; kana: string; birthDate: string; gender: Gender }) =>
      createPsAccount({
        accessToken: tokenData.accessToken,
        ...params,
      }),
    [tokenData.accessToken],
  );

  useEffect(() => {
    if (idToken) {
      const { exp } = jwtDecode<CognitoTokenData>(idToken);
      if (exp && exp * 1000 - 3 * 60 * 1000 > Date.now()) {
        // NOTE: 有効期限が残っている場合は何もしない
        return;
      }
    }

    if (refreshToken && cognitoUserName) {
      signIn();
    }
  }, [refreshToken, cognitoUserName, signIn, idToken]);

  // Auto Refresh Token (1min)
  const doRefreshToken = useCallback(async () => {
    if (!refreshToken) {
      // NOTE: refreshTokenがない場合は何もしない
      return false;
    }

    if (idToken) {
      const { exp } = jwtDecode<CognitoTokenData>(idToken);
      if (exp && exp * 1000 - 3 * 60 * 1000 > Date.now()) {
        // NOTE: 有効期限が残っている場合は何もしない
        return false;
      }
    }

    const result = await fetchApi({
      url: '/ps/v1/token',
      method: 'put',
      data: {
        cognitoUserName,
        refreshToken,
      },
    })
      .then((res) => res.data)
      .catch((error) => {
        console.warn({
          refreshToken,
          cognitoUserName,
        });
        console.warn('refresh token error', error);
        return undefined;
      });
    if (!result) {
      signOut();
      return false;
    }
    if (result.accessToken && result.idToken) {
      updateCurrentAuth(result.idToken);

      setTokenData((prev) => ({
        ...prev,
        accessToken: result.accessToken,
        idToken: result.idToken,
      }));
    }
    return true;
  }, [cognitoUserName, idToken, refreshToken, signOut, updateCurrentAuth]);

  useQuery({
    queryKey: ['doRefreshToken'],
    queryFn: doRefreshToken,
    refetchInterval: 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!refreshToken,
  });

  const value: ContextValue = useMemo(
    () => ({
      user,
      patients,
      mainPatientId,
      currentPatientId,
      setCurrentPatientId,
      isLoading,
      scopes,
      signIn,
      signInWithCode,
      signInToCenterServer,
      signOut,
      createAccount,
      isSnsNeedRedirect,
      setIsSnsNeedRedirect,
    }),
    [
      user,
      patients,
      mainPatientId,
      currentPatientId,
      isLoading,
      scopes,
      signIn,
      signInWithCode,
      signInToCenterServer,
      signOut,
      createAccount,
      isSnsNeedRedirect,
      setIsSnsNeedRedirect,
    ],
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}

export function AuthProtector(props: { children: React.ReactNode }) {
  const { children } = props;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const urlParams = {
    cognitoUserName: searchParams.get('cognitoUserName') || '',
    refreshToken: searchParams.get('refreshToken') || '',
  };

  const [refreshToken] = useLocalStorage('harmo-web-auth-refresh-token', urlParams.refreshToken);
  const [cognitoUserName] = useLocalStorage('harmo-web-auth-cognito-username', urlParams.cognitoUserName);

  const { user, currentPatientId } = useAuth();

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    // NOTE: refreshTokenがない場合
    if (!refreshToken && !cognitoUserName && !urlParams.cognitoUserName && !urlParams.refreshToken) {
      navigate('/auth/sign-in', {
        state: {
          from: {
            pathname: location.pathname,
            search: location.search,
            hash: location.hash,
            params,
          },
        },
      });
    }
  }, [
    cognitoUserName,
    location.hash,
    location.pathname,
    location.search,
    navigate,
    params,
    refreshToken,
    urlParams.cognitoUserName,
    urlParams.refreshToken,
  ]);

  if (!user || !currentPatientId) {
    return <Loading />;
  }
  return <>{children}</>;
}

export const useAuth = () => {
  let contextValue = useContext(context);
  if (!contextValue) {
    contextValue = DEFAULT_CONTEXT_VALUE;
    console.error('useAuth must be used within a AuthProvider');
  }
  return contextValue;
};
