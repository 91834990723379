import { lazy } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { GlobalProvider } from '@/components/GlobalStore';
import { GlobalToaster } from '@/components/GlobalToaster';
import { ReactQueryProvider } from '@/components/ReactQueryProvider';
import { AuthProtector, AuthProvider } from '@/hooks/useAuth';
import TabLayout from '@/layouts/TabLayout';
import NotFound from '@/pages/NotFound';

const AuthSignIn = lazy(() => import('@/pages/auth/SignIn'));
const AuthOAuth2Callback = lazy(() => import('@/pages/auth/OAuth2Callback'));
const AuthSignOut = lazy(() => import('@/pages/auth/SignOut'));
const AuthCreateAccount = lazy(() => import('@/pages/auth/CreateAccount'));

const OAuthTest = lazy(() => import('@/pages/oauth/Test'));
const OAuthClients = lazy(() => import('@/pages/oauth/Clients'));
const OAuthAuthScreen = lazy(() => import('@/pages/oauth/AuthScreen'));
const OAuthChooseAccount = lazy(() => import('@/pages/oauth/ChooseAccount'));
const OAuthComplete = lazy(() => import('@/pages/oauth/Complete'));

const EmapFindByName = lazy(() => import('@/pages/emap/FindByName'));
const EmapFindByArea = lazy(() => import('@/pages/emap/FindByArea'));
const EmapFindFromMap = lazy(() => import('@/pages/emap/FindFromMap'));

const JanCode = lazy(() => import('@/pages/jan-code'));

const ProjectsList = lazy(() => import('@/pages/projects/ProjectsList'));
const ProjectsProjectDetail = lazy(() => import('@/pages/projects/ProjectDetail'));
const ProjectsQrCodeScanner = lazy(() => import('@/pages/projects/QrCodeScanner'));
const ProjectsCreateProject = lazy(() => import('@/pages/projects/CreateProject'));

const DebugPage = lazy(() => import('@/pages/debug'));

function App() {
  return (
    <ReactQueryProvider>
      <GlobalProvider>
        <GlobalToaster />
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<TabLayout />}>
                <Route index element={<Navigate replace to="/oauth/clients" />} />
                <Route path="auth">
                  <Route path="sign-in" element={<AuthSignIn />} />
                  <Route path="oauth2-callback" element={<AuthOAuth2Callback />} />
                  <Route path="sign-out" element={<AuthSignOut />} />
                  <Route path="create-account" element={<AuthCreateAccount />} />
                </Route>
                <Route
                  path="oauth"
                  element={
                    <AuthProtector>
                      <Outlet />
                    </AuthProtector>
                  }
                >
                  <Route path="clients" element={<OAuthClients />} />
                  <Route path="test" element={<OAuthTest />} />
                  <Route path="auth" element={<OAuthAuthScreen />} />
                  <Route path="choose-account" element={<OAuthChooseAccount />} />
                  <Route path="complete" element={<OAuthComplete />} />
                </Route>
                <Route path="emap">
                  <Route path="" element={<EmapFindByName />} />
                  <Route path="find-by-area" element={<EmapFindByArea />} />
                  <Route path="find-from-map" element={<EmapFindFromMap />} />
                </Route>
                <Route
                  path="jan-code"
                  element={
                    <AuthProtector>
                      <JanCode />
                    </AuthProtector>
                  }
                />
                <Route
                  path="projects"
                  element={
                    <AuthProtector>
                      <Outlet />
                    </AuthProtector>
                  }
                >
                  <Route path="" element={<ProjectsList />} />
                  <Route path="qr-code-scanner" element={<ProjectsQrCodeScanner />} />
                  <Route path="create" element={<ProjectsCreateProject />} />
                  <Route path=":projectId" element={<ProjectsProjectDetail />} />
                </Route>
              </Route>
              <Route path="debug" element={<DebugPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </GlobalProvider>
    </ReactQueryProvider>
  );
}

export default App;
